<template>
	<create-template-page />
</template>
<script>
import CreateTemplatePage from '@/components/agreement/agreementType/CreateTemplatePage/CreateTemplatePage.vue';

export default {
	name: 'AgreementTypeCreateView',
	components: { CreateTemplatePage },
};
</script>
